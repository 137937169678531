@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MarkPro-Bold SKY';
  src: url('/public/fonts/MarkPro-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Book SKY';
  src: url('/public/fonts/MarkPro-Book.ttf') format('truetype');
}
@font-face {
  font-family: 'MarkPro-Black SKY';
  src: url('/public/fonts/MarkPro-Black.ttf') format('truetype');
}
@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MarkPro-Light SKY";
  src: url('/public/fonts/MarkPro-Light.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "MarkPro";
  src: url('/public/fonts/MarkPro-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
body {
  margin: 0;
  font-family: 'MarkPro-Book SKY', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
